<template>
  <div class="dashboard-content" data-app>
    <!-- Content -->
    <v-card>
      <div class="filters">
        <h3 class="text-center">
          {{ title }}
          <span
            ><small>{{ company.name ? company.name : '' }}</small></span
          >
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="#0c2c7c"
                dark
                class="mb-2"
                style="float: right"
                @click.prevent="handleCreate()"
                >{{ $t('Views.AdminCompanies.createButton') }}
              </v-btn>
            </template>
            <span>{{ $t('Views.AdminCompanies.createButton') }}</span>
          </v-tooltip>
        </h3>

        <label>{{ $t('Views.AdminCompanies.filterText') }}:</label>

        <v-form ref="formCompany" v-model="valid" style="width: 100%" class="my-2">
          <v-row class="mx-0" style="max-width: calc(100% - 90px)">
            <v-col cols="12" lg="3" md="4" sm="6" style="padding: 1px 2px 1px 2px">
              <v-text-field
                dense
                solo
                hide-details="auto"
                :label="$t('Views.AdminCompanies.filterSearch')"
                v-model="filters.search"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="2" md="3" sm="6" style="padding: 1px 2px 1px 2px">
              <multiselect
                track-by="id"
                label="name"
                deselect-label=""
                select-label=""
                :placeholder="$t('Views.AdminCompanies.filterCountry')"
                :custom-label="countryName"
                v-model="filters.countryId"
                :options="countries"
                :multiple="false"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="false"
              ></multiselect>
            </v-col>
            <v-col cols="12" lg="1" md="2" sm="6" style="padding: 1px 2px 1px 2px">
              <multiselect
                style="min-width: 80px"
                track-by="id"
                label="name"
                deselect-label=""
                select-label=""
                :placeholder="$t('Views.AdminCompanies.filterActive')"
                v-model="filters.active"
                :options="activeOptions"
                :multiple="false"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="false"
              ></multiselect>
            </v-col>
            <!-- <v-col cols="12" lg="3" md="4" sm="6" class="py-0">
              <v-text-field
                type="date"
                hide-details="auto"
                :label="$t('Views.AdminCompanies.filterStar')"
                v-model="filters.date.startAt"
              >
                <template v-slot:message
                  >{{ $t('Views.AdminCompanies.filterStar') }}
                </template></v-text-field
              >
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" class="py-0">
              <v-text-field
                type="date"
                hide-details="auto"
                :label="$t('Views.AdminCompanies.filterEnd')"
                v-model="filters.date.endAt"
                ><template v-slot:label>
                  <span>{{ $t('Views.AdminCompanies.filterEnd') }}</span>
                </template></v-text-field
              >
            </v-col> -->
            <v-btn
              color="#0c2c7c"
              style="min-width: auto; position: absolute; right: 50px; height: 25px"
              dark
              class="px-1 py-1"
              v-if="filterActivated"
              @click.prevent="
                filterActivated = false
                cleanFilters()
                getCompanies()
              "
            >
              <v-icon dark> mdi-delete </v-icon>
            </v-btn>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="#0c2c7c"
                  style="min-width: auto; position: absolute; right: 10px; height: 25px"
                  dark
                  class="px-1 py-1"
                  @click.prevent="
                    filterActivated = true
                    getCompanies()
                  "
                  ><v-icon dark> mdi-filter </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('Views.AdminCompanies.filterButtonText') }}</span>
            </v-tooltip>
          </v-row>
          <!-- <v-row>

          </v-row> -->
        </v-form>
      </div>

      <simple-list
        :title="''"
        :dense="true"
        :headers="headers"
        :items="items"
        :totalItems="totalItems"
        :itemsPerPage="itemsPerPage"
        :loadingText="loadingText"
        :itemKey="'id'"
        :hideFooter="true"
        :hideHeader="false"
        :multiSort="false"
        :loading="loading"
        :class="'my-4 pb-2'"
        :sortable="false"
        :page="page"
        :pages="pages"
        :extraButtoms="extraButtoms"
        :showCreate="false"
        :showDelete="true"
        :orderOptions="orderOptions"
        :orderBy="orderBy"
        @create:item="handleCreate()"
        @edit:item="handleEdit($event)"
        @delete:item="deleteItem($event)"
        @update:page="pagination($event)"
        @orderEvent="handleOrder($event)"
      />
    </v-card>
    <modal name="crudModal" :height="'70%'" :width="'80%'" :adaptive="true">
      <h3 class="text-center" v-if="flow == 'create'">
        {{
          companyType === 1
            ? $t('Views.AdminCompanies.createText1')
            : $t('Views.AdminCompanies.createText2')
        }}
      </h3>
      <h3 class="text-center" v-if="flow == 'edit'">
        {{
          companyType === 1
            ? $t('Views.AdminCompanies.editText1')
            : $t('Views.AdminCompanies.editText2')
        }}
      </h3>

      <div class="card" style="padding: 2rem; margin: 20px">
        <span v-if="flow == 'edit' && item.created_at"
          >{{ $t('Views.AdminCompanies.createDate') }}:
          {{
            createdAt.toLocaleDateString({
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })
          }}</span
        >

        <v-form ref="formCompany" v-model="valid" style="width: 100%" class="row">
          <v-row class="mx-0 mt-4">
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                :counter="140"
                :rules="[rules.required, rules.max]"
                :label="$t('Views.AdminCompanies.labelName')"
                v-model="name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                :counter="140"
                :rules="[rules.required, rules.max]"
                :label="$t('Views.AdminCompanies.labelDescription')"
                v-model="description"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                :rules="[rules.email]"
                :label="$t('Views.AdminCompanies.labelEmail')"
                v-model="email"
                type="email"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                v-mask="phoneMask"
                :rules="[rules.maxNumber]"
                :label="$t('Views.AdminCompanies.labelPhone1')"
                v-model="phone"
                type="text"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                v-mask="phoneMask"
                :rules="[rules.maxNumber]"
                :label="$t('Views.AdminCompanies.labelPhone2')"
                v-model="phone2"
                type="text"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                :label="$t('Views.AdminCompanies.labelWebsite')"
                v-model="website"
                :rules="[rules.url]"
                persistent-hint
                :hint="$t('Views.AdminCompanies.labelWebsiteExample')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                v-if="companyType === 1"
                :label="$t('Views.AdminCompanies.labelLatitude')"
                v-model="latitude"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                v-if="companyType === 1"
                :label="$t('Views.AdminCompanies.labelLongitude')"
                v-model="longitude"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12" v-if="companyType === 1">
              <v-text-field
                :rules="[rules.max]"
                :label="$t('Views.AdminCompanies.labelAddress')"
                v-model="address"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="12">
              <div
                class="row"
                style="display: flex; justify-content: center; align-items: center"
              >
                <img
                  v-if="flow == 'edit' && item.logo"
                  :src="item.logo.route"
                  :alt="item.name"
                  style="width: 40%"
                />
                <v-file-input
                  v-if="item.logo && item.logo.route"
                  v-model="image"
                  chips
                  :rules="[rules.fileSize]"
                  show-size
                  accept="image/png, image/jpeg, image/jpg, image/gif"
                  :label="$t('Views.AdminCompanies.labelLogo')"
                  style="width: 60%"
                ></v-file-input>
                <v-file-input
                  v-else
                  v-model="image"
                  chips
                  :rules="[rules.required, rules.fileSize]"
                  ref="file"
                  show-size
                  accept="image/png, image/jpeg, image/jpg"
                  :label="$t('Views.AdminCompanies.labelLogo')"
                  style="width: 60%"
                ></v-file-input>
              </div>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12" v-if="companyType !== 1">
              <v-row no-gutters>
                <span style="width: 40%"
                  >{{ $t('Views.AdminCompanies.labelCategories') }}:</span
                >
                <multiselect
                  style="width: 60%"
                  track-by="id"
                  label="name"
                  :placeholder="$t('Views.AdminCompanies.placeholder')"
                  v-model="selectedCategories"
                  :options="categories"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="false"
                ></multiselect
              ></v-row>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12" v-if="companyType === 1">
              <v-row no-gutters>
                <span style="width: 30%"
                  >{{
                    companyType === 1
                      ? $t('Views.AdminCompanies.labelCountry')
                      : $t('Views.AdminCompanies.labelCountries')
                  }}:</span
                >
                <multiselect
                  style="width: 70%"
                  track-by="id"
                  :rules="[rules.required]"
                  label="name"
                  :placeholder="$t('Views.AdminCompanies.placeholder')"
                  v-model="countryId"
                  :options="countries"
                  :multiple="companyType === 1 ? false : true"
                  :close-on-select="false"
                  :clear-on-select="true"
                  :preserve-search="false"
                ></multiselect
              ></v-row>
            </v-col>

            <v-col cols="12" lg="3" md="6" sm="12">
              <v-checkbox
                :label="$t('Views.AdminCompanies.labelActive')"
                v-model="active"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-form>
      </div>
      <v-col cols="12" style="margin-bottom: 10px; height: 10%">
        <div class="row">
          <v-btn
            :dark="valid"
            style="margin: auto; margin-top: 10px"
            v-if="flow == 'create'"
            :disabled="!valid"
            color="#0c2c7c"
            @click="createItem()"
          >
            {{ $t('Views.AdminCompanies.createButton') }}
          </v-btn>
          <v-btn
            :dark="valid"
            style="margin: auto; margin-top: 10px"
            v-if="flow == 'edit'"
            :disabled="!valid"
            color="#0c2c7c"
            @click="saveItem()"
          >
            {{ $t('Views.AdminCompanies.saveButton') }}
          </v-btn>
          <v-btn
            dark
            style="margin: auto; margin-top: 10px"
            color="#0c2c7c"
            @click="reset"
          >
            {{ $t('Views.AdminCompanies.cleanButton') }}
          </v-btn>
          <v-btn
            dark
            style="margin: auto; margin-top: 10px"
            color="#0c2c7c"
            @click="$modal.hide('crudModal')"
          >
            {{ $t('Views.AdminCompanies.cancelButton') }}
          </v-btn>
        </div>
      </v-col>
    </modal>
  </div>
</template>

<script>
  import SimpleList from 'Components/Listing/SimpleList.vue'

  export default {
    name: 'Companies',
    props: ['companyType'],
    components: {
      SimpleList,
    },
    data() {
      return {
        config: {},
        companies: [],
        company: {},
        loading: false,
        items: [],
        categories: [],
        selectedCategories: [],
        page: 1,
        pages: 1,
        item: {},
        totalItems: 0,
        itemsPerPage: 10,
        flow: '',
        filterActivated: false,
        filters: {
          userId: null,
          search: '',
          date: { startAt: '', endAt: '' },
          active: null,
          countryId: null,
        },
        activeOptions: [
          { id: 1, name: this.$t('Views.AdminCompanies.filterActiveOption2') },
          { id: 0, name: this.$t('Views.AdminCompanies.filterActiveOption3') },
        ],
        phoneMask: [
          '+',
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
        ],
        active: false,
        type: 0,
        address: '',
        cityId: '',
        email: '',
        keyword: '',
        latitude: '',
        longitude: '',
        link: '',
        phone: '',
        name: '',
        userId: '',
        description: '',
        phone2: '',
        website: '',
        cities: [],
        countries: [{ id: null, name: 'Todos' }],
        createdAt: '',
        toDay: new Date(),
        dateRange: '',
        countryId: '',
        selectCountryData: [],
        selectCityData: [],
        loadingText: 'Cargando registros',
        datas: [],
        image: null,
        arrayOfData: [],
        valid: true,
        rules: {
          required: (v) => !!v || 'Requerido.',
          min: (v) => (v ? v.length >= 8 : true) || '8 caracteres como mínimo',
          max: (v) =>
            (v ? v.length <= 140 : true) || 'Debe poser menos de 140 caracteres',
          maxNumber: (v) =>
            (v ? v.length <= 18 : true) || 'Debe poser menos de 18 caracteres',
          url: (v) => this.isURL(v) || 'La URL es inválida',
          email: (v) => (v ? /.+@.+\..+/.test(v) : true) || 'E-mail debe ser válido',
          fileSize: (v) =>
            (v ? v.size < 1024000 : true) ||
            'El peso del archivo debe ser inferior a 1MB',
        },
      }
    },
    watch: {
      companyType() {
        this.getCompanies()
      },
    },
    created() {
      this.getCompany()
      this.getCompanies()
      this.getCountries()
      this.getCategories()
    },
    computed: {
      companyId() {
        if (this.$router.params.id) {
          return this.$router.params.id
        } else {
          return null
        }
      },
      extraButtoms() {
        if (this.companyType === 1) {
          return [
            {
              text: this.$t('Views.AdminCompanies.extraButton1'),
              link: 'Franchises',
              icon: 'mdi-store',
              params: { name: 'id', value: 'id' },
              typeLink: 'name',
            },
            {
              text: this.$t('Views.AdminCompanies.extraButton2'),
              link: 'companies/data',
              icon: 'mdi-file-document-edit',
              query: { name: 'id', value: 'id' },
              typeLink: 'path',
            },
            {
              text: this.$t('Views.AdminCompanies.extraButton3'),
              link: 'companies/galery',
              icon: 'mdi-image-multiple',
              query: { name: 'id', value: 'id' },
              typeLink: 'path',
            },
            {
              text: this.$t('Views.AdminCompanies.extraButton4'),
              link: 'CompanyDirectors',
              icon: 'mdi-sitemap',
              query: { name: 'id', value: 'id' },
              typeLink: 'name',
            },
          ]
        } else {
          return false
        }
      },
      title() {
        switch (this.companyType) {
          case 0:
            return this.$t('Views.AdminCompanies.title2')
          case 1:
            return this.$t('Views.AdminCompanies.title1')
          default:
            return ''
        }
      },
      headers() {
        return [
          { text: this.$t('Views.AdminCompanies.headerName'), value: 'name' },
          { text: this.$t('Views.AdminCompanies.headerActive'), value: 'activeText' },
          { text: 'Acciones', value: 'actions', align: 'center', width: '200px' },
        ]
      },
      orderOptions() {
        return [
          {
            id: 1,
            name: this.$t('Views.AdminCompanies.orderOption1'),
            order: 'created_at',
            by: 'desc',
          },
          {
            id: 2,
            name: this.$t('Views.AdminCompanies.orderOption2'),
            order: 'created_at',
            by: 'asc',
          },
          {
            id: 3,
            name: this.$t('Views.AdminCompanies.orderOption3'),
            order: 'name',
            by: 'asc',
          },
          {
            id: 4,
            name: this.$t('Views.AdminCompanies.orderOption4'),
            order: 'updated_at',
            by: 'desc',
          },
        ]
      },
      orderBy() {
        return {
          id: 1,
          name: this.$t('Views.AdminCompanies.orderOption1'),
          order: 'created_at',
          by: 'desc',
        }
      },
    },
    methods: {
      handleOrder(orderBy) {
        this.orderBy = orderBy
        this.getCompanies()
      },
      isURL(str) {
        if (!str || str === '') return true
        let url
        try {
          url = new URL(str)
        } catch (_) {
          return false
        }

        return url.protocol === 'http:' || url.protocol === 'https:'
      },
      fullName({ name, lastname, email }) {
        if (name && name != 'Todos') {
          if (lastname && email) {
            return `${name} ${lastname} | ${email}`
          } else {
            return name
          }
        } else {
          return name
        }
      },
      countryName({ name, iso2 }) {
        if (name && iso2) {
          return `${name} (${iso2})`
        } else if (name) {
          return name
        }
      },
      cleanFilters() {
        this.filters = {
          userId: null,
          search: '',
          active: null,
          countryId: null,
          date: {},
        }
      },
      async getCompanies() {
        this.loading = true
        let queryParams = ''
        let orderString = ''
        if (this.orderBy.order && this.orderBy.by) {
          orderString = '&order=' + this.orderBy.order + '&by=' + this.orderBy.by
        }
        if (this.filterActivated) {
          let searchString = ''
          let countryString = ''
          let activeString = ''
          let companyString = ''
          let dateString = ''
          if (this.filters.date.startAt != '' && this.filters.date.endAt != '') {
            dateString =
              '&startAt=' +
              this.filters.date.startAt +
              '&endAt=' +
              this.filters.date.endAt
          }
          if (this.filters.search != '') {
            searchString = '&search=' + this.filters.search
          }
          if (this.filters.countryId && this.filters.countryId.id) {
            countryString = '&country_id=' + this.filters.countryId.id
          }
          if (this.filters.active && this.filters.active.id == 1) {
            activeString = '&active=1'
          } else if (this.filters.active && this.filters.active.id == 0) {
            activeString = '&active=0'
          }
          if (this.$router.params.id) {
            companyString = '&company_id=' + this.$route.params.id
          }
          queryParams =
            searchString + countryString + activeString + dateString + companyString
        }
        axios
          .get(
            'companies?page=' +
              this.page +
              '&type=' +
              this.companyType +
              queryParams +
              orderString
          )
          .then((response) => {
            this.items = response.data.data
            if (this.items.length < 1) {
              this.loadingText = 'No hay registros'
            }
            this.totalItems = response.data.total
            this.itemsPerPage = response.data.per_page
            if (response.data.from) {
              this.page = response.data.current_page
              this.pages = response.data.last_page
            } else {
              this.page = 1
              this.pages = 1
            }
            this.items.map((x) => {
              x.activeText = x.active ? 'Si' : 'No'
            })
            this.loading = false
          })
      },
      async getCompany() {
        axios.get('companies/' + this.$route.params.id).then((response) => {
          this.company = response.data
        })
      },
      async getCategories() {
        axios.get('categories?limit=300&type=0').then((response) => {
          this.categories = response.data
        })
      },
      async getCountries() {
        axios.get('countries?limit=300' + this.countryId).then((response) => {
          this.countries = this.countries.concat(response.data)
        })
      },
      async handleCreate() {
        this.flow = 'create'
        this.item = {}
        this.name = ''
        this.userId = ''
        this.description = ''
        this.email = ''
        this.phone = ''
        this.phone2 = ''
        this.latitude = ''
        this.longitude = ''
        this.keyword = ''
        this.website = ''
        this.cityId = ''
        this.address = ''
        this.countryId = ''
        this.active = false
        this.selectedCategories = []

        this.$modal.show('crudModal')
      },
      async handleEdit(item) {
        this.flow = 'edit'
        this.item = item
        this.image = null
        this.name = this.item.name
        this.description = this.item.description
        this.email = this.item.email
        this.phone = this.item.phone
        this.phone2 = this.item.phone2
        this.latitude = this.item.latitude
        this.longitude = this.item.longitude
        this.keyword = this.item.keyword
        this.website = this.item.website
        this.address = this.item.address
        this.countryId = this.item.countries
        this.active = this.item.active
        this.selectedCategories = this.item.categories
        this.createdAt = new Date(this.item.created_at)
        this.dateRange = Math.ceil(
          Math.abs(this.createdAt - this.toDay) / (1000 * 60 * 60 * 24)
        )
        this.$modal.show('crudModal')
      },

      validate() {
        this.$refs.formCompany.validate()
      },

      reset() {
        this.$refs.formCompany.reset()
      },

      async createItem() {
        this.active = this.active ? 1 : 0
        let formData = new FormData()
        this.config.headers = {
          'Content-Type': 'multipart/form-data',
        }
        if (this.image) {
          formData.append('file', this.image)
        }
        formData.append('fileType', 'logo')
        formData.append('name', this.name)
        formData.append('type', this.companyType)
        formData.append('description', this.description)
        if (this.website && this.website != '') {
          formData.append('website', this.website.toLowerCase())
        }
        if (this.latitude && this.latitude != '') {
          formData.append('latitude', this.latitude)
        }
        if (this.longitude && this.longitude != '') {
          formData.append('longitude', this.longitude)
        }
        if (this.address && this.address != '') {
          formData.append('address', this.address)
        }
        if (this.keyword && this.keyword != '') {
          formData.append('keyword', this.keyword.toLowerCase())
        }
        if (this.phone && this.phone != '') {
          formData.append('phone', this.phone)
        }
        if (this.phone2 && this.phone2 != '') {
          formData.append('phone2', this.phone2)
        }
        if (this.email && this.email != '') {
          formData.append('email', this.email)
        }
        formData.append('showcontact', 1)
        formData.append('active', this.active)
        if (this.companyType === 0 && this.countryId && this.countryId.length > 0) {
          this.countryId.forEach((country) => {
            formData.append('countries[]', country.id)
          })
        } else if (this.companyType === 1 && this.countryId && this.countryId.id) {
          formData.append('countries[]', this.countryId.id)
        }
        if (this.companyType === 0 && this.selectedCategories.length > 0) {
          this.selectedCategories.forEach((category) => {
            formData.append('categories[]', category.id)
          })
        }
        axios.post('companies', formData, this.config).then(() => {
          this.page = 1
          this.getCompanies()
          this.$modal.hide('crudModal')
        })
      },

      async saveItem() {
        this.active = this.active ? 1 : 0
        let formData = new FormData()
        this.config.headers = {
          'Content-Type': 'multipart/form-data',
        }
        if (this.image) {
          formData.append('file', this.image)
        }
        if (this.item.logo) {
          formData.append('fileId', this.item.logo.id)
        }
        formData.append('fileType', 'logo')
        formData.append('_method', 'patch')
        formData.append('name', this.name)
        formData.append('type', this.companyType)
        formData.append('description', this.description)
        if (this.website && this.website != '') {
          formData.append('website', this.website.toLowerCase())
        }
        if (this.latitude && this.latitude != '') {
          formData.append('latitude', this.latitude)
        }
        if (this.longitude && this.longitude != '') {
          formData.append('longitude', this.longitude)
        }
        if (this.address && this.address != '') {
          formData.append('address', this.address)
        }
        if (this.keyword && this.keyword != '') {
          formData.append('keyword', this.keyword.toLowerCase())
        }
        if (this.phone && this.phone != '') {
          formData.append('phone', this.phone)
        }
        if (this.phone2 && this.phone2 != '') {
          formData.append('phone2', this.phone2)
        }
        if (this.email && this.email != '') {
          formData.append('email', this.email)
        }
        formData.append('showcontact', 1)
        formData.append('active', this.active)
        if (this.companyType === 0 && this.countryId && this.countryId.length > 0) {
          this.countryId.forEach((country) => {
            formData.append('countries[]', country.id)
          })
        } else if (this.companyType === 1 && this.countryId && this.countryId.id) {
          formData.append('countries[]', this.countryId.id)
        }
        if (this.companyType === 0 && this.selectedCategories.length > 0) {
          this.selectedCategories.forEach((category) => {
            formData.append('categories[]', category.id)
          })
        }
        axios.post('companies/' + this.item.id, formData, this.config).then(() => {
          this.page = 1
          this.getCompanies()
          this.$modal.hide('crudModal')
        })
      },

      async deleteItem(item) {
        axios.delete('companies/' + item.id).then(() => {
          this.page = 1
          this.items = []
          this.getCompanies()
        })
      },

      async pagination(page) {
        this.page = page
        this.items = []
        await this.getCompanies()
      },
    },
  }
</script>
<style lang="scss">
  @import '@/assets/scss/cruds/_crudStyles.scss';
</style>
